import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import PageLayout from '../layouts/Page';
import { Helmet } from 'react-helmet';

import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { GatsbyImage } from 'gatsby-plugin-image';

class BlogPostTemplate extends React.Component {
  render() {
    const options = {
      renderNode: {
        'embedded-asset-block': (node) => {
          console.log(node);
          const { gatsbyImageData } = node.data.target;
          if (!gatsbyImageData) {
            // asset is not an image
            return null;
          }
          return (
            <div class="text-center">
              <GatsbyImage image={gatsbyImageData} />
            </div>
          );
        }
      }
    };

    const post = get(this.props, 'data.contentfulBlogPost');

    return (
      <PageLayout>
        <Helmet>
          <title>{post.title}</title>
          <meta name="description" content={post.description} />
        </Helmet>
        <div className="blog-post-container">
          <div className="blog-post">
            <div className="bg-white">
              <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8">
                <h1 className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  {post.title}
                </h1>
                <p className="mt-2 block text-base text-center tracking-tight text-gray-400">
                  {format(new Date(post.publishDate), 'dd MMMM yyyy', {
                    locale: frLocale
                  })}
                </p>
                {post.heroImage?.gatsbyImageData?.images?.fallback?.src && (
                  <div className="my-4">
                    <img
                      alt={post.title}
                      src={
                        post.heroImage?.gatsbyImageData?.images?.fallback?.src
                      }
                    />
                  </div>
                )}
                <div className="mt-6 prose prose-green prose-lg text-gray-500 mx-auto">
                  <div className="blog-post-content prose-lg ">
                    {renderRichText(post.mainContent, options)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String) {
    contentfulBlogPost(slug: { eq: $slug }) {
      contentful_id
      title
      publishDate
      heroImage {
        gatsbyImageData(width: 1232)
      }
      mainContent {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
          }
        }
      }
      __typename
    }
  }
`;
